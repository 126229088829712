import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import routes from "../../routes";
import * as webviewUtils from "../../utils/webview";
import AppContext from "../../contexts/AppContext";
import Creative from "../../photolab/Creative";
import processingManager from "../../photolab/ProcessingManager";
import i18n from "../../i18n";
import {hitEvent, hits, logCreativeResult, logEvent, userEvents} from "../../utils/log";
import {signalEvent, signals} from "../../utils/signals";
import {assetUrl, debounce} from "../../utils/etc";
import CreativeView from "./CreativeView";
import {
  webviewAnalyticsEvent, webviewAppIds,
  webviewConsumable, webviewInApp, webviewOpenBrowser,
  webviewPreloadRewardedAd, webviewShowBanner
} from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import SubscribeBannerView from "../SubscribeBannerView";
import PurchaseBannerView from "../PurchaseBannerView";
import inappsHelper from "../../helpers/inapps.helper";
import {saveAs} from "file-saver";
import {creativeName, transformToDownloadUrl} from "../../utils/creative";
import RewardedAdBannerView from "../RewardedAdBannerView";
import {createCreativeByPromptConfig} from "../../photolab/config";
import TouchEffect from "../../components/TouchEffect/TouchEffect";
import ShareModal from "../result/ShareModal";
import watermarkHandler from "../../utils/watermark.handler";
import Loader from "../../components/Loader/Loader";
import FileChooseButton from "../../components/FileChooseButton";
import DropdownSelectorsWrapper from '../../components/DropdownSelector/DropdownSelectorsWrapper';
import Header from '../../components/AppHeader/AppHeader';
import {BtnsGroupStyled, ButtonStyled, ButtonChooseContainerStyled, ContainerStyled, ParentStyled, SliderContainerStyled, SliderSlidesCountStyled, SliderSlidesCountValueStyled, SpinnerStyled} from './ResultPage.style';
import {goToProcessing} from "../../utils/url";
import {isTokenSymbol, isWebviewApp} from "../../utils/config.utils";
import {extraKeys, genderKeys, hairStylizationKeys} from "../../photolab/etc";
import Processing from "../../photolab/Processing";
import {canShowNativeAds, preloadNativeAds, showNativeAds} from "../../utils/native-ads";
import {getInAppSku, getSubscriptionSku} from "../../utils/inapp";
import SubscribeBannerV2View from "../SubscribeBannerV2View";
import LimitModal from '../../components/LimitModal';
import LimitModalV2 from '../../components/LimitModalV2';
import HotlookBannerView from "../HotlookBannerView";
import {HOTLOOK_APPSTORE_LINK, HOTLOOK_PLAY_MARKET_LINK} from "../../utils/constants";

export const watermarkConfig = {
  url: assetUrl("/assets/watermarks/toonme.png"),
  position: "bottom-right",
  x: 3, // %
  y: 1, // %
  percentage: 30,
};

export default class ResultPage extends React.Component {

  state = {
    isReady: false,
    activeIndex: 0,
    rewardedAdIsLoaded: false,
    isDownloadProcessing: false,
    purchaseSku: getInAppSku(),
    subscriptionSku: getSubscriptionSku(window.clientConfig.canSubscribeWithTrial),
  };

  swiperRef = React.createRef();

  componentDidMount() {
    document.addEventListener("visibilitychange", this.handleDocumentVisibilityChange);

    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("ResultPage.handleWebviewTabSelected", 300, () => {
          hitEvent(hits.RESULT_VISIT);
          logEvent(userEvents.PAGE_RESULT);

          webviewAnalyticsEvent("result_screen_shown", [
            clientStorage.getSelectedPhotosAmount(),
          ]);
        });
      }
    }, true);

    window.webviewEventsListeners.rewardedAdClosed.setListener((flag) => {
      if (flag && processingManager.processing) {
        this.setState({rewardedAdIsLoaded: false});

        webviewAnalyticsEvent("rewarded_finish", [
          clientStorage.getSelectedPhotosAmount(),
          window.clientConfig.features.rewardedAdUnitId,
        ]);
        webviewPreloadRewardedAd(window.clientConfig.features.rewardedAdUnitId);
        webviewAnalyticsEvent("rewarded_requested", [
          clientStorage.getSelectedPhotosAmount(),
          window.clientConfig.features.rewardedAdUnitId,
        ]);

        processingManager.processing.setExtra("rewarded_is_shown", true);
        processingManager.update();

        this.swiperRef && this.handleActiveIndexChange(this.swiperRef);
      }
    });

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_RESULT);
    }

    if (window.clientConfig.isWebview) {
      this.enqueueCheckRewardedAdPreloaded(0);

      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const restoredProcessing = processingManager.restore();

      if (restoredProcessing) {
        try {
          processingManager.start(restoredProcessing);

          this.setState({isReady: true});
        } catch (err) {
          console.error(err);
          processingManager.clear();
          this.props.history.replace(routes.INDEX);
        }
      } else {
        processingManager.clear();
        this.props.history.replace(routes.INDEX);
      }
    } else {
      this.setState({isReady: true});
      this.handleProcessingChanged();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("visibilitychange", this.handleDocumentVisibilityChange);
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
    clearTimeout(this.checkRewardedAdPreloadedTimer);
  }

  handleDocumentVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      this.enqueueCheckRewardedAdPreloaded(0);
    } else {
      clearTimeout(this.checkRewardedAdPreloadedTimer);
    }
  };

  enqueueCheckRewardedAdPreloaded = (delay = 1000) => {
    if (!window.clientConfig.features.isRewardedAdModel) {
      return;
    }

    clearTimeout(this.checkRewardedAdPreloadedTimer);

    this.checkRewardedAdPreloadedTimer = setTimeout(() => {
      webviewUtils.webviewIsRewardedAdPreloaded(window.clientConfig.features.rewardedAdUnitId).then((isLoaded) => {
        this.setState({rewardedAdIsLoaded: isLoaded});
        if (!isLoaded) {
          this.enqueueCheckRewardedAdPreloaded(1000);
        }
      });
    }, delay);
  };

  handleProcessingChanged = () => {
    if (processingManager.processing) {
      const processing = processingManager.processing;
      const version = processing.getExtra(extraKeys.version, 0);
      if (version < window.appConfig.processings.latestVersion) {
        processingManager.clear();
        this.props.history.replace(routes.INDEX);
      }

      this.setState({
        _creativesTotal: processing.creatives.length,
        _creativesProcessed: processing.creatives.filter((c) => c.isProcessed).length,
        _creativesPending: processing.creatives.filter((c) => c.isPending).length,
        _creativesFailed: processing.creatives.filter((c) => c.isFailed).length,
      });
    }

    clientStorage.setLatestSelectedImages([]);
  };

  getCreativeByIndex = (position) => {
    const processing = processingManager.processing;
    const items = processing.getItemsOrder();
    if (position >= items.length) {
      return null;
    }

    const creative = processing.creatives[position];
    if (creative) {
      return creative;
    }

    const newCreative = createCreativeByPromptConfig(
      processing,
      processing.getItemByPosition(position),
      processing.getExtra(extraKeys.gender),
      processing.getExtra(extraKeys.stylization),
      processing.getExtra(extraKeys.hairStylization),
      processing.getExtra(extraKeys.strength),
    );

    newCreative.setExtra(Creative.EXTRA_KEEP_PENDING, true);

    if (position >= window.clientConfig.features.freeCreativesAmount) {
      newCreative.setExtra(Creative.EXTRA_IS_PAYABLE, true);
    }

    return newCreative;
  };

  canPay = (position) => {
    if (position < window.clientConfig.features.freeCreativesAmount) {
      return [true, "free"];
    }

    if (window.clientConfig.isPro) {
      return [true, "pro"];
    }

    if (processingManager.processing.getExtra("rewarded_is_shown")) {
      return [true, "rewarded_ad"];
    }

    const purchaseSku = getInAppSku();
    const purchase = this.context.purchases
      .filter((p) => p.product_id === purchaseSku)
      .sort((a, b) => b.available - a.available)
      .first();

    if (purchase && purchase.available > 0) {
      return [true, "purchase", purchase];
    }

    return [false];
  }

  handleActiveIndexChange = (swiper) => {
    const position = swiper.activeIndex;
    const processing = processingManager.processing;
    const creative = this.getCreativeByIndex(position);
    const canPay = this.canPay(position);

    if (
      window.clientConfig.isWebview
      && window.clientConfig.features.isSubscribeModel
      && !processingManager.processing.getExtra("postdownload_is_shown")
      && !canPay[0]
    ) {
      processingManager.processing.setExtra("postdownload_is_shown", true);

      webviewShowBanner("pro_web", () => {
        //
      });
    }

    if (!creative.isStarted && canPay[0]) {
      creative.setExtra(Creative.EXTRA_IS_PAID, true);
      creative.removeExtra(Creative.EXTRA_KEEP_PENDING);
      processing.addCreative(creative);
      processingManager.update();

      this.logStartCreative(creative, position);

      if (canPay[1] === "rewarded_ad") {
        processingManager.processing.removeExtra("rewarded_is_shown");
      }
      else if (canPay[1] === "purchase") {
        const purchase = canPay[2];
        if (purchase) {
          inappsHelper.consume(purchase.id, 1).then((res) => {
            this.context.updatePurchase(res.data);
          });
        }
      }
    }

    this.setState({activeIndex: position}, () => {
      processingManager.processing.setExtra("active_index", position);
      processingManager.update();
    });
  }

  startPurchaseOrAd = () => {
    if (window.clientConfig.features.isSubscribeModel) {
      this.handleSubscribeButtonClick();
    } else if (window.clientConfig.features.isRewardedAdModel && this.state.rewardedAdIsLoaded) {
      webviewAnalyticsEvent("rewarded_start", [
        clientStorage.getSelectedPhotosAmount(),
        window.clientConfig.features.rewardedAdUnitId,
      ]);
      webviewUtils.webviewShowRewardedAd(
        window.clientConfig.features.rewardedAdUnitId,
        "onRewardedAdShown",
        "onRewardedAdClosed"
      );
    } else if (window.clientConfig.features.isPurchaseModel) {
      this.handlePurchaseButtonClick();
    }
  };

  handleDownloadButtonClick = () => {
    const position = this.state.activeIndex;
    const creative = this.getCreativeByIndex(position);

    hitEvent(hits.DOWNLOAD);
    logEvent(userEvents.DOWNLOAD, {
      group: creative.group,
      template_id: creative.templateId,
      position: position,
    });

    this.logShareCreative(creative, position, "download");

    logCreativeResult(
      creativeName(creative),
      processingManager.processing.files.map((f) => ({url: f.url})),
      [{url: creative.result}],
      true,
      {
        gender: creative.getExtra(extraKeys.gender),
        detected_gender: processingManager.processing.getExtra(extraKeys.detectedGender),
        stylization: creative.getExtra(extraKeys.stylization),
        strength: creative.getExtra(extraKeys.strength),
        combo: creative.getExtra(Creative.EXTRA_COMBO_STEPS),
      }
    );

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    let fileName = "raw";
    const withWatermark = !window.clientConfig.isPro;

    if (withWatermark) {
      fileName += "_wm";
    }

    const existsFile = creative.getFile(fileName);
    if (existsFile) {
      this.handleDownload(creative, existsFile);
      return;
    }

    this.setState({isDownloadProcessing: true});

    watermarkHandler(
      creative.getFile("raw"),
      window.clientConfig.isPro ? null : watermarkConfig,
      null
    ).then((resultUrl) => {
      creative.setFile(fileName, resultUrl);
      this.setState({isDownloadProcessing: false});
      this.handleDownload(creative, resultUrl);
    }).catch((error) => {
      console.log(error);
      this.setState({isDownloadProcessing: false});
      this.handleDownload(creative);
    });
  };

  handleDownload = (creative, fileUrl) => {
    const imageUrl = fileUrl || creative.getFile("raw");

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewShare({
        providers: window.clientConfig.isWebviewIOS ? encodeURIComponent("[0]") : "[0]",
        imageUrl: encodeURIComponent(imageUrl),
      });

      const providers = {
        ...this.context.installedApps,
        ...{
          facebookMessenger: false,
          tiktok: false,
        },
      };

      if (!isWebviewApp(webviewAppIds.toonme, webviewAppIds.photolabFree)) {
        providers.snapchat = false;
      }

      this.context.pushModal(<ShareModal
        key="ResultPage_ShareModal"
        hashtag={"#toonme"}
        providers={providers}
        onShare={(provider) => this.handleShare(provider, creative, imageUrl)}
      />);
    } else {
      const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
      saveAs(transformToDownloadUrl(imageUrl), fileName);
    }

    if (!processingManager.processing.hasExtra("signal_download_is_sent")) {
      processingManager.processing.setExtra("signal_download_is_sent", true);
      signalEvent(signals.download);
    }
  };

  handleShare = (provider, creative, imageUrl) => {
    const providers = [];
    provider === "snapchat" && providers.push(webviewUtils.shareProvidersIds.snapchat);
    provider === "instagram" && providers.push(webviewUtils.shareProvidersIds.instagram);
    provider === "facebook" && providers.push(webviewUtils.shareProvidersIds.facebook);
    provider === "whatsapp" && providers.push(webviewUtils.shareProvidersIds.whatsapp);
    provider === "telegram" && providers.push(webviewUtils.shareProvidersIds.telegram);
    provider === "tiktok" && providers.push(webviewUtils.shareProvidersIds.tiktok);

    webviewUtils.webviewShare({
      providers: "[" + providers.join(",") + "]",
      imageUrl: encodeURIComponent(imageUrl),
    });

    this.logShareCreative(creative, this.state.activeIndex, provider);
  };

  handleNextButtonClick = () => {
    const creative = this.getCreativeByIndex(this.state.activeIndex);
    const lastPaidIndex = processingManager.processing.creatives
      .findLastIndex((c) => !c.getExtra(Creative.EXTRA_IS_PAYABLE, false));

    if (window.clientConfig.features.hotifyBanner
      && window.clientConfig.features.isSubscribeModel
      && creative.isNotPaid
      && this.state.activeIndex - lastPaidIndex === 2) {
      const url = new URL(window.clientConfig.isWebviewIOS ? HOTLOOK_APPSTORE_LINK : HOTLOOK_PLAY_MARKET_LINK);

      if (isWebviewApp(...webviewAppIds.photolabAll)) {
        url.searchParams.append("utm_source", "photolab-wv");
      } else if (isWebviewApp(...webviewAppIds.toonmeAll)) {
        url.searchParams.append("utm_source", "toonme-wv");
      } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
        url.searchParams.append("utm_source", "newprofilepic-webview");
      } else {
        url.searchParams.append("utm_source", "app-webview");
      }

      url.searchParams.append("utm_medium", "banner");
      url.searchParams.append("utm_campaign", "hotbody");

      webviewOpenBrowser(url.toString());
      return;
    }

    if (creative.isNotPaid) {
      debounce("handleNextButtonClick_startPurchaseOrAd", 300, () => {
        this.startPurchaseOrAd();
      });
      return;
    }

    webviewAnalyticsEvent("continue_button_tapped", [
      clientStorage.getSelectedPhotosAmount(),
      this.state.activeIndex + 1,
    ]);

    this.swiperRef.slideNext();
  };

  handleBackButtonClick = () => {
    processingManager.clear();
    this.props.history.replace(routes.INDEX);
  };

  handleSubscribeButtonClick = () => {
    webviewInApp(this.state.subscriptionSku);
  };

  handlePurchaseButtonClick = () => {
    const purchaseSku = getInAppSku();

    processingManager.processing.setExtra("awaiting_billed_sku", purchaseSku);
    processingManager.update();

    hitEvent(hits.PURCHASE_CLICK);
    logEvent(userEvents.PURCHASE_CLICK, {
      sku: purchaseSku,
      type: "inapp",
    });
    clientStorage.setAwaitingBilledSku(purchaseSku);

    webviewConsumable(purchaseSku);
  };

  logStartCreative = (creative, position) => {
    this.logCreative("generation_start", creative, position);
  }

  logShareCreative = (creative, position, provider) => {
    this.logCreative("save_and_share", creative, position, {provider});
  }

  logCreative = (eventName, creative, position, extra = {}) => {
    const processing = processingManager.processing;
    const genders = processing.getExtra(extraKeys.gender).split(" and ");
    const humans = processing.getExtra(Processing.EXTRA_FACE_COUNT, 1);

    webviewAnalyticsEvent(eventName, [
      clientStorage.getSelectedPhotosAmount(),
      processing.getExtra("cropped_image_size"),
      humans,
      genders.first(),
      creative.getExtra(extraKeys.stylization),
      creative.getExtra(extraKeys.hairStylization),
    ], {
      "wt_barbify2": {
        "templ_id": creative.templateId,
        "pos": position + 1,
        "gender2": genders.last(),
        "eyes_dist": processing.getExtra("eyes_dist_1"),
        ...extra,
      },
    });
  }

  handleCreativeResultView = (creative, position) => {
    logEvent(userEvents.CREATIVE_VIEW, {
      group: creative.group,
      template_id: creative.templateId,
      position,
      image_url: creative.result,
      gender: creative.getExtra("gender"),
    });

    this.logCreative("template_shown", creative, position);
  };

  handleGenderChanged = (nextGender) => {
    const processing = processingManager.processing;
    const currentCreative = processing.creatives[this.state.activeIndex];

    if (currentCreative.getExtra(extraKeys.gender) === nextGender) {
      return;
    }

    const itemsMale = processing.getExtra(Processing.EXTRA_ITEMS_ORDER_MALE);
    const itemsFemale = processing.getExtra(Processing.EXTRA_ITEMS_ORDER_FEMALE);
    const currentLength = this.state.activeIndex + 1;

    if (itemsMale.length < currentLength) {
      const startLength = itemsMale.length;
      for (let i = startLength; i < currentLength; i++) {
        itemsMale.push(itemsMale[i % startLength]);
      }
      processing.setExtra(Processing.EXTRA_ITEMS_ORDER_MALE, itemsMale);
    }

    if (itemsFemale.length < currentLength) {
      const startLength = itemsFemale.length;
      for (let i = startLength; i < currentLength; i++) {
        itemsFemale.push(itemsFemale[i % startLength]);
      }
      processing.setExtra(Processing.EXTRA_ITEMS_ORDER_FEMALE, itemsFemale);
    }

    const nextCreative = createCreativeByPromptConfig(
      processing,
      processing.getItemByPosition(this.state.activeIndex, nextGender),
      nextGender,
      currentCreative.getExtra(extraKeys.stylization),
      currentCreative.getExtra(extraKeys.hairStylization),
      currentCreative.getExtra(extraKeys.strength)
    );

    processing.creatives.splice(this.state.activeIndex, 1, nextCreative);
    processing.setExtra(extraKeys.gender, nextGender);

    processingManager.update();

    this.logCreative("gender_changed", nextCreative, this.state.activeIndex);
    this.logStartCreative(nextCreative, this.state.activeIndex);
  };

  handleStylizationChanged = (nextStylization) => {
    const processing = processingManager.processing;
    const currentCreative = processing.creatives[this.state.activeIndex];

    if (currentCreative.getExtra(extraKeys.stylization) === nextStylization) {
      return;
    }

    const nextCreative = createCreativeByPromptConfig(
      processing,
      processing.getItemByPosition(this.state.activeIndex),
      currentCreative.getExtra(extraKeys.gender),
      nextStylization,
      currentCreative.getExtra(extraKeys.hairStylization),
      currentCreative.getExtra(extraKeys.strength)
    );

    processing.creatives.splice(this.state.activeIndex, 1, nextCreative);
    processing.setExtra(extraKeys.stylization, nextStylization);
    processingManager.update();

    this.logCreative("face_style_changed", nextCreative, this.state.activeIndex);
    this.logStartCreative(nextCreative, this.state.activeIndex);
  };

  handleHairStylizationChanged = (nextHairStylization) => {
    const processing = processingManager.processing;
    const currentCreative = processing.creatives[this.state.activeIndex];

    if (currentCreative.getExtra(extraKeys.gender) !== genderKeys.female && !window.clientConfig.isPro) {
      webviewShowBanner("pro_web", () => {
        //
      });
      return;
    }

    if (currentCreative.getExtra(extraKeys.hairStylization) === nextHairStylization) {
      return;
    }

    const nextCreative = createCreativeByPromptConfig(
      processing,
      processing.getItemByPosition(this.state.activeIndex),
      currentCreative.getExtra(extraKeys.gender),
      currentCreative.getExtra(extraKeys.stylization),
      nextHairStylization,
      currentCreative.getExtra(extraKeys.strength)
    );

    processing.creatives.splice(this.state.activeIndex, 1, nextCreative);
    processing.setExtra(extraKeys.hairStylization, nextHairStylization);
    processingManager.update();

    this.logCreative("hair_style_changed", nextCreative, this.state.activeIndex);
    this.logStartCreative(nextCreative, this.state.activeIndex);
  };

  handleStrengthChanged = (nextStrength) => {
    const processing = processingManager.processing;
    const currentCreative = processing.creatives[this.state.activeIndex];

    if (currentCreative.getExtra(extraKeys.strength) === nextStrength) {
      return;
    }

    const nextCreative = createCreativeByPromptConfig(
      processing,
      processing.getItemByPosition(this.state.activeIndex),
      currentCreative.getExtra(extraKeys.gender),
      currentCreative.getExtra(extraKeys.stylization),
      currentCreative.getExtra(extraKeys.hairStylization),
      nextStrength,
    );

    processing.creatives.splice(this.state.activeIndex, 1, nextCreative);
    processing.setExtra(extraKeys.strength, nextStrength);
    processingManager.update();

    this.logCreative("strength_changed", nextCreative, this.state.activeIndex);
    this.logStartCreative(nextCreative, this.state.activeIndex);
  };

  handleFileChooseButtonClick = () => {
    signalEvent(signals.photoSelectClick);
    webviewAnalyticsEvent("change_photo_button_tapped", [
      clientStorage.getSelectedPhotosAmount(),
      this.state.activeIndex + 1,
    ]);

    if (window.clientConfig.features.isRewardedAdModel) {
      webviewPreloadRewardedAd(window.clientConfig.features.rewardedAdUnitId);
      webviewAnalyticsEvent("rewarded_requested", [
        clientStorage.getSelectedPhotosAmount(),
        window.clientConfig.features.rewardedAdUnitId,
      ]);
    }

    if (canShowNativeAds()) {
      preloadNativeAds();
    }
  };

  handleFilesSelected = (files) => {
    clientStorage.incrementSelectedPhotosAmount();

    hitEvent(hits.RESULT_PHOTO_SELECT);

    goToProcessing(this.props.history, files);

    if (canShowNativeAds()) {
      showNativeAds();
    }
  };

  getLastPaidIndex = () => {
    const processing = processingManager.processing;
    return processing?.creatives.findLastIndex((c) => !c.getExtra(Creative.EXTRA_IS_PAYABLE, false));
  }

  renderItem = (position) => {
    const creative = this.getCreativeByIndex(position);
    const isActive = position === this.state.activeIndex;
    const isOverlayed = creative.isNotPaid;
    const isMultiface = processingManager.processing.getExtra(Processing.EXTRA_FACE_COUNT) > 1;
    const originalImageUrl = processingManager.processing.files.first().url;
    const lastPaidIndex = processingManager.processing.creatives
      .findLastIndex((c) => !c.getExtra(Creative.EXTRA_IS_PAYABLE, false));

    let banner = null;
    if (isOverlayed && !this.canPay(position)[0]) {
      if (window.clientConfig.features.isSubscribeModel) {
        switch (position - lastPaidIndex) {
          case 2:
            if (window.clientConfig.features.hotifyBanner) {
              banner = <HotlookBannerView
                isOnScreen={isActive}
                position={position}
              />;
            } else {
              banner = <SubscribeBannerV2View
                isOnScreen={isActive}
                position={position}
                sku={this.state.subscriptionSku}
                swiper={this.swiperRef}
                images={[
                  assetUrl("assets/images/animation/1.webp"),
                  assetUrl("assets/images/animation/2.webp"),
                  assetUrl("assets/images/animation/3.webp"),
                  assetUrl("assets/images/animation/4.webp"),
                  assetUrl("assets/images/animation/5.webp"),
                ]}
              />;
            }
            break;

          case 3:
            banner = <SubscribeBannerView
              title={i18n.t("subscribe_banner_v3__title")}
              text={i18n.t("subscribe_banner_v3__text_1")}
              bannerOriginal="/assets/images/banner/banner-orig-v3.webp"
              bannerResult="/assets/images/banner/banner-result-v3.webp"
              isOnScreen={isActive}
              position={position}
              sku={this.state.subscriptionSku}
              swiper={this.swiperRef}
            />;
            break;

          default:
            banner = <SubscribeBannerView
              title={i18n.t("subscribe_banner__title")}
              text={i18n.t("subscribe_banner__text_1")}
              bannerOriginal="/assets/images/banner/banner-orig.webp"
              bannerResult="/assets/images/banner/banner-result.webp"
              isOnScreen={isActive}
              position={position}
              sku={this.state.subscriptionSku}
              swiper={this.swiperRef}
            />;
        }
      } else if (window.clientConfig.features.isRewardedAdModel && this.state.rewardedAdIsLoaded) {
        banner = <RewardedAdBannerView
          isOnScreen={isActive}
          position={position}
        />;
      } else if (window.clientConfig.features.isPurchaseModel) {
        banner = <PurchaseBannerView
          isOnScreen={isActive}
          position={position}
        />;
      }
    }

    const creativeOptions = [{
      hidden: creative.isPending || isMultiface,
      value: creative.getExtra(extraKeys.gender),
      items: [{
        key: genderKeys.female,
        icon: "gender-female",
        label: i18n.t("processing_female_button"),
      }, {
        key: genderKeys.male,
        icon: "gender-male",
        label: i18n.t("processing_male_button"),
      }],
      onChange: this.handleGenderChanged,
    },
    // {
    //   hidden: creative.isPending,
    //   value: creative.getExtra(extraKeys.stylization),
    //   items: [{
    //     key: stylizationKeys.glamour,
    //     icon: "styles-glamour",
    //     label: i18n.t("result__face_stylisation_glamour"),
    //   }, {
    //     key: stylizationKeys.doll,
    //     icon: "styles-doll",
    //     label: i18n.t("result__face_stylisation_doll"),
    //   }],
    //   onChange: this.handleStylizationChanged,
    // },
    {
      hidden: creative.isPending || isMultiface,
      value: creative.getExtra(extraKeys.hairStylization),
      items: [{
        key: hairStylizationKeys.yes,
        icon: "hair-stylization-yes",
        label: i18n.t("result__picture_hair_stylization_yes"),
      }, {
        key: hairStylizationKeys.no,
        icon: "hair-stylization-no",
        label: i18n.t("result__picture_hair_stylization_no"),
        isPro: creative.getExtra(extraKeys.gender) !== genderKeys.female && !window.clientConfig.isPro,
      }],
      onChange: this.handleHairStylizationChanged,
    }, {
      hidden: creative.isPending || true,
      value: creative.getExtra(extraKeys.strength),
      items: [{
        key: "low",
        icon: "strength-low",
        label: i18n.t("result__picture_intensity_low"),
      }, {
        key: "medium",
        icon: "strength-medium",
        label: i18n.t("result__picture_intensity_medium"),
      }, {
        key: "high",
        icon: "strength-high",
        label: i18n.t("result__picture_intensity_super"),
      }],
      onChange: this.handleStrengthChanged,
    }];

    return <SwiperSlide key={creative.id}>
      <DropdownSelectorsWrapper selectors={creativeOptions} />
      <CreativeView
        isSelected={isActive}
        creative={creative}
        position={position}
        isOverlayed={isOverlayed}
        originalImageUrl={originalImageUrl}
        onResultView={this.handleCreativeResultView}
      />
      {banner}
    </SwiperSlide>;
  }

  render() {
    if (!this.state.isReady || !processingManager.processing) {
      return <React.Fragment />;
    }

    const processingsItems = processingManager.processing.getItemsOrder();
    const processingsItemsAmount = processingsItems.length;
    const initialSlide = processingManager.processing.getExtra("active_index", 0);
    const activeCreative = this.getCreativeByIndex(this.state.activeIndex);
    const activeCreativeIsPaywall = activeCreative.isNotPaid;
    const nextCreative = this.getCreativeByIndex(this.state.activeIndex + 1);
    const nextButtonText = activeCreativeIsPaywall ? i18n.t("continue") : i18n.t("more");
    const nextButtonIsDisabled = activeCreativeIsPaywall
      ? false
      : (activeCreative.isPending || (this.state.activeIndex === (processingsItemsAmount - 1)));

    const lastPaidIndex = this.getLastPaidIndex();

    const subscribeBannersCount = 3

    const canSlideToNext = (activeCreative.isProcessed && nextCreative && !nextCreative.isStarted)
      || (nextCreative && nextCreative.isStarted)
      || (window.clientConfig.features.isSubscribeModel && nextCreative && this.state.activeIndex < lastPaidIndex + subscribeBannersCount);

    return <ParentStyled>
      <ContainerStyled>
        <Header
          title="result"
          onHandleClick={this.handleBackButtonClick}
        />

        <SliderContainerStyled>
          <Swiper
            allowSlideNext={canSlideToNext && (activeCreative.isFinished || activeCreativeIsPaywall)}
            centeredSlides={true}
            initialSlide={initialSlide}
            spaceBetween={10}
            slidesPerView={window.clientConfig.isWebDesktop ? 1 : 1.12}
            onActiveIndexChange={this.handleActiveIndexChange}
            onTouchEnd={(swiper) => {
              if (!canSlideToNext && swiper.swipeDirection === "next") {
                processingManager.processing.setExtra("postdownload_is_shown", true);

                webviewShowBanner("pro_web", () => {
                  //
                });
              }
            }}
            onSwiper={(swiper) => this.swiperRef = swiper}>
            {processingsItems.map((_, position) => this.renderItem(position))}
          </Swiper>
        </SliderContainerStyled>

        <SliderSlidesCountStyled>
          {/*<ImageContextStyled className={(activeCreativeIsPaywall || activeCreative.isPending) ? "disabled" : ""}>*/}
          {/*  <ImageContextInputStyled*/}
          {/*    id="image-context"*/}
          {/*    type="checkbox"*/}
          {/*    disabled={activeCreativeIsPaywall || activeCreative.isPending}*/}
          {/*    checked={activeCreative.getExtra(extraKeys.contextType) === contextTypeKeys.original}*/}
          {/*    onChange={(e) => {*/}
          {/*      this.handleContextTypeChanged(e.target.checked ? contextTypeKeys.original : contextTypeKeys.template);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <ImageContextLabelStyled htmlFor="image-context">*/}
          {/*    Original context*/}
          {/*  </ImageContextLabelStyled>*/}
          {/*</ImageContextStyled>*/}
          <SliderSlidesCountValueStyled>
            {this.state.activeIndex + 1}/{processingsItemsAmount}
          </SliderSlidesCountValueStyled>
        </SliderSlidesCountStyled>

        <BtnsGroupStyled>
          <ButtonStyled
            disabled={!activeCreative.isProcessed}
            transparent
            onClick={this.handleDownloadButtonClick}>
            {i18n.t("download")}
            <TouchEffect />
          </ButtonStyled>
          <ButtonStyled
            disabled={nextButtonIsDisabled}
            animate
            full
            activeCreativeIsPaywall={activeCreativeIsPaywall}
            onClick={this.handleNextButtonClick}>
            {!(!activeCreativeIsPaywall && activeCreative.isPending) && nextButtonText}
            {!activeCreativeIsPaywall && activeCreative.isPending && <SpinnerStyled />}
            {(activeCreative.isFinished || activeCreativeIsPaywall) && <SvgMoreButtonArrowIcon />}
            <TouchEffect />
          </ButtonStyled>
          <ButtonChooseContainerStyled style={activeCreativeIsPaywall ? {visibility: "hidden"} : {}}>
            <FileChooseButton
              onClick={this.handleFileChooseButtonClick}
              onFilesSelected={this.handleFilesSelected}>
              {i18n.t("result__button_change_photo")}
              <TouchEffect />
            </FileChooseButton>
          </ButtonChooseContainerStyled>
        </BtnsGroupStyled>
      </ContainerStyled>

      {this.state.isDownloadProcessing && <Loader />}
    </ParentStyled>;
  }
}

function SvgMoreButtonArrowIcon() {
  return <svg viewBox="0 0 83 19">
    <defs>
      <clipPath id="6ablmodosa">
        <path d="M1242 0v2208H0V0h1242z" />
      </clipPath>
    </defs>
    <g clipPath="url(#6ablmodosa)" transform="translate(-1023 -1913)">
      <path d="m1086.5 1913 19 9.5-19 9.5v-8h-63v-3h63v-8z" />
    </g>
  </svg>
}

ResultPage.contextType = AppContext;
