module.exports = {
  "get_the_app": "GET THE APP",

  "index_logo": "Barbify",
  "index_title": "Join the Hot Body trend!",
  "index_subtitle": "Turn your body to an even hotter version of itself and join the trend.",
  "index_footer_privacy": "Privacy Policy",
  "index_footer_contact": "Contact Us",

  "upload_choose_photo_button": "Choose photo",

  "result_title": "Results",
  "result_notice_1": "You look amazing! <span class=\"text-decoration\">Share to inspire others</span>",
  "result_notice_2": "Time to show off your makeover! <span class=\"text-decoration\">Tap to share</span>",
  "result_notice_3": "Stunning looks are for sharing. <span class=\"text-decoration\">Tap if you agree</span>",
  "result_notice_4": "Don’t keep your look to yourself, <span class=\"text-decoration\">share it now!</span>",

  "try_another_photo": "Try another photo",
  "retry": "Retry",
  "next": "next",

  "photochooser_title": "Upload your photos",
  "photochooser_photos_count": "Uploaded: <span>{{num}} photos</span>",
  "photochooser_photos_error": "(Some photos cannot be used. Tap to learn more)",
  "photochooser_delete_photo": "Select a photo to delete",
  "photochooser_rule_1": "<span>At least 2 photos</span>",
  "photochooser_rule_2": "All the photos are of <span>the same person</span>",
  "photochooser_rule_3": "<span>Only one person</span> in a photo",

  "button__select_photos": "select photos",
  "button__select_more": "select more",
  "button__add_more": "Add more",
  "button__proceed": "proceed",
  "button__select_to_delete": "Select a photo to delete",
  "button__tap_to_delete": "Tap a photo to delete",
  "button__cancel": "cancel",
  "button__retry": "retry",
  "button__try_again": "try again",
  "button__delete": "delete",
  "button__ok": "OK",
  "btn__upgrade": "Upgrade",
  "btn__speed_up": "Speed Up",

  "download": "Download",
  "more": "More",
  "continue": "Continue",
  "save_and_share": "Save & Share",

  "error__default_message": "Something went wrong. Try again.",
  "error_title": "Something went wrong!",
  "error__api_code_1": "Internal error",
  "error__api_code_2": "Incorrect params",
  "error__api_code_3": "Processing failure",
  "error__api_code_401": "Unauthorized request",
  "error__api_code_404": "Not found",
  "error__api_code_410": "The requested content is deleted",
  "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

  "error__network_message": "Please check your network connection.",
  "error_overload": "We are terribly sorry, but our servers experience too much of a load currently due to skyrocketing app downloads. Please, come back a little later. We are working hard to fix everything ASAP.",

  "watermark_screen__title": "",
  "draggable_watermark_tooltip": "<span>Tap&Drag</span> to move <br>& delete watermark",
  "watermark_removal_modal__title": "Yep, you can remove the watermark, but…",
  "watermark_removal_modal__text1": "We’d like you to keep it and let your friends know about our app. Don’t feel like keeping it? Spread the hash-word about {{app_name}}.",
  "watermark_removal_modal__text2": "The entire amount of money raised goes to a charity fund!",
  "watermark_removal_modal__notice": "<span class=\"text-bold\">TIP:</span> you can just drag the watermark to the position you want.",
  "watermark_removal_modal__choice": "Your choice?",
  "watermark_removal_modal__confirm": "Remove watermark",
  "watermark_removal_modal__cancel": "Spread the good!",
  "watermark_removal_modal__close": "Close",

  "processing_title": "Processing",
  "processing_text_1": "AI is looking for a stunning look for you 🔍",
  "processing_text_2": "Any idea what it’s gonna look like? 🙈",
  "processing_text_3": "Just wait for it… 🪄",
  "processing_text_4": "AI’s fantasy is coming true 🧚",
  "processing_footer_text": "Your Gender:",
  "processing_female_button": "Female",
  "processing_male_button": "Male",
  "processing_human_button": "Other",

  "subscribe_subscription_terms": "Renews automatically. Cancel any time.&nbsp;<span>Terms</span>",

  "cancel": "cancel",

  "subscribe_banner__text": "<span class=\"text-bold\">Get <span class=\"text-pink\">unlimited</span> access to <span class=\"text-pink\">premium</span> artworks.</span>",
  "subscribe_banner__subtext": "Enjoy 3 days for FREE, then {{price}}/week",

  "subscribe_banner__title": "Your hottest looks are yet to be <span class=\"text-yellow\">created</span>",
  "subscribe_banner__text_1": "Exclusive looks, outfits, and scenes.",
  "subscribe_banner__text_2_wt": "Enjoy 3 days for FREE, then {{price}}/week",
  "subscribe_banner__text_2_nt": "Enjoy all premium features with {{price}}/week subscription",
  "subscribe_banner__text_3": "Renews&nbsp;automatically. Cancel&nbsp;any&nbsp;time.&nbsp;<span class='text-underline'>Terms</span>",

  "subscribe_banner_v2__title": "Your results may be even <span class=\"text-yellow\">better</span>",
  "subscribe_banner_v2__text_1": "Upgrade for high-quality premium images",

  "subscribe_banner_v3__title": "There is so much <span class=\"text-yellow\">more</span>...",
  "subscribe_banner_v3__text_1": "Hot body trend is only 1% of our effects. Get the rest...",

  "subscribe_banner_processing__text": "Better quality,remove ads and watermark, unlimited image creation",
  "subscribe_banner_processing__title": "Too slow?<br>Get faster with <span>PRO</span>",

  "hotlook_banner_processing__title": "🌶️ EVEN HOTTER LOOKS 🌶️",
  "hotlook_banner_processing__text": "in our special spicy app",
  "hotlook_banner_processing__text_2": "Sponsored",

  "hotlook_banner__title": "Your results can be",
  "hotlook_banner__subtitle": "🌶️ EVEN HOTTER 🌶️",
  "hotlook_banner__text": "Get our special SPICY app and go with AI’s fantasy flow",
  "hotlook_banner__text_2": "Sponsored",

  "purchase_banner__text": "<span class=\"text-bold\">Get 100+ <span class=\"text-pink\">premium</span> artworks</span>",
  "purchase_banner__subtext": "Enjoy more variations with {{price}} one-time purchase",

  "rewarded_ad_banner__text": "<span class=\"text-bold text-pink\">Keep looking</span>",
  "rewarded_ad_banner__subtext": "Unlock other results by watching a short sponsored video.",

  "result__picture_intensity": "Intensity:",
  "result__picture_intensity_low": "Low",
  "result__picture_intensity_medium": "Medium",
  "result__picture_intensity_super": "Super",

  "result__picture_hair_stylization_yes": "Glamour",
  "result__picture_hair_stylization_no": "Realistic",

  "result__face_stylisation": "Face:",
  "result__face_stylisation_doll": "Doll",
  "result__face_stylisation_glamour": "Glamour",

  "result__button_change_photo": "Change photo",
  "result__button_creative_retry": "Retry",
  "result__creative_failed_message": "Oops, something went wrong with this style. 😔 Choose any other style or try again.",

  "share_modal__title": "Saved to gallery!",
  "share_modal__text_1": "You look amazing! Share it.",
  "share_modal__text_2": "<span>We love you!</span> Love us back by adding hashtag to your post",
  "share_modal__copy": "Copy",
  "share_modal__copied": "Copied",

  "limit_modal_v1__title": "You are about to reach the limit…",
  "limit_modal_v1__text": "Free version allows choosing {{limit}} photos per day. Edit {{left}} left for today or",

  "limit_modal_v2__title": "You’ve reached the daily limit of processings.",
  "limit_modal_v2__text": "Or get back tomorrow for free processings.",

  "limit_modal__list_title": "GO PRO now to enjoy:",
  "limit_modal__list_item_1": "unlimited number of photos and processings",
  "limit_modal__list_item_2": "exclusive looks and scenes",
  "limit_modal__list_item_3": "premium-quality results in less time",
  "limit_modal__list_item_4": "the rest 99% of the effects in the app",
  "limit_modal__continue_btn": "Continue in limited mode",
  "limit_modal__upgrade_btn": "Go full mode",
};


