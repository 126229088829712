import styled from "styled-components";

export const ItemStyled  = styled.main`
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 40px;
  background-color: #b4d7fe;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.processed {
    animation: none;
    overflow: hidden;

    img {
      width: auto;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
      object-position: top center;
      transition: opacity 320ms;
      
      &.original {
        opacity: 0;
      }
    }
    
    &.original-shown {
      img {
        opacity: 0;
        &.original {
          opacity: 1;
        }
      }
    }
  }

  &.failed {
    // background-color: rgba(255, 255, 255, 0.08);
    background-image: none;
    overflow: hidden;
  }

  @media all and (max-height: 710px) and (orientation: portrait) {
    margin: 0 auto;
  }

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
`;

export const OverlayStyled  = styled.div`
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const FailedStyled  = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
`;

export const FailedImageStyled  = styled.img`
  position: static;
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  transform: translate(0);
`;

export const FailedTextStyled  = styled.p`
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: rgba(34, 34, 34, 0.7);
  margin-bottom: 32px;
`;

export const FailedBtnStyled  = styled.button`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.24px;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  width: 112px;
  height: 32px;
  border-radius: 100px;
  background-color: rgb(255, 98, 165);
`;
