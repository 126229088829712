import axios from "axios";
import "./utils/ga";
import {hitEvent, hits, logEvent, logProcessingsTimings, userEvents} from "./utils/log";
import {signalEvent, signals} from "./utils/signals";
import * as photolabApi from "./photolab/api";

window.appGlobalCache = {};
window.sessionStorage.clear();

window.axios = axios.create();
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["X-Client-Token"] = window.clientConfig.token;
window.axios.defaults.headers.common["X-Project-Key"] = window.appConfig.project.key;

// ---

window.onerror = function (message, file, line, col, error) {
  hitEvent(hits.jsGlobalError, 1, true, 0);
  signalEvent(signals.jsGlobalError, 1, true);
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

// ---

photolabApi.addListener({
  onTaskResult: (task) => {
    if (window.appConfig.isDebug) {
      console.log("photolabTask", task);
    }

    if (task.startedAt && [8815, 8979].indexOf(parseInt(task.templateName)) > -1) {
      logProcessingsTimings(task.finishedAt - task.startedAt, {group: "t_" + task.templateName});
    }
  },
});