import React from "react";
import {ImageAnimationStyled} from "./ImageAnimation.style";

export default class ImageAnimation extends React.Component {
    render() {
        return (
            <ImageAnimationStyled>
                {this.props.images.map((image, i) => (
                    <img src={image} alt="" key={i} />
                ))}
            </ImageAnimationStyled>
        )
    }
}