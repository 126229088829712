import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import ImageAnimation from "../components/ImageAnimation/ImageAnimation";

const images = [
  "/assets/images/animation/11.webp",
  "/assets/images/animation/12.webp",
  "/assets/images/animation/13.webp",
  "/assets/images/animation/14.webp",
  "/assets/images/animation/15.webp",
];

export default class SubscribeBannerV2View extends React.Component {
  render() {
    return <div className="subscribe-banner hotlook-banner v2 v3">
      <h2 className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("hotlook_banner__title")}} />
      <h3 className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("hotlook_banner__subtitle")}} />
      <ImageAnimation images={images} />
      <p className="text-white" dangerouslySetInnerHTML={{__html: i18n.t("hotlook_banner__text")}} />
      <p className="text-terms" dangerouslySetInnerHTML={{__html: i18n.t("hotlook_banner__text_2")}} />
    </div>;
  }
}

SubscribeBannerV2View.contextType = AppContext;
