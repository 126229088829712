import React from "react";
import AppContext from "../../contexts/AppContext";
import Creative from "../../photolab/Creative";
import processingManager from "../../photolab/ProcessingManager";
import Processing from "../../photolab/Processing";
import {photolabGenderTask, PhotolabResponseError, photolabSimpleTask} from "../../photolab/api";
import uploadHandler from "../../utils/upload.handler";
import {createCreativeByPromptConfig, getPromptsForCouples, getPromptsForSingle} from "../../photolab/config";
import ErrorView from "../../components/ErrorView";
import routes from "../../routes";
import {hitEvent, hits, logEvent, logProcessingsTimings, userEvents} from "../../utils/log";
import LoadingView from "./LoadingView/LoadingView";
import {extraKeys, genderKeys, hairStylizationKeys, stylizationKeys} from "../../photolab/etc";
import clientStorage from "../../utils/client-storage";
import {normalizeError} from "../../photolab/handlers/helpers";
import {webviewAnalyticsEvent, webviewShowBanner} from "../../utils/webview";
import {promisifyImage} from "../../utils/image";
import processingsCounter from "../../utils/processings-counter";
import {signalEvent, signals} from "../../utils/signals";
import * as processingHelper from "../../helpers/processing.helper";

export default class ProcessingPage extends React.Component {

  state = {
    files: [],
    gender: null,
    error: null,
    faceCount: 0,
    imageUrl: null,
    imageIsBlurred: false,
  };

  processingTimerId = null;
  processingTimer = 0;
  processingRestored = false;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.startProcessingTimer, false);

    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    const locationState = this.props.location.state || {};
    const files = clientStorage.hasLatestSelectedImages()
      ? clientStorage.getLatestSelectedImages()
      : locationState.files;

    const processing = processingManager.restore();

    if (processing) {
      this.processingRestored = true;
      processingManager.start(processing);
    } else if (files) {
      this.handleUploadFiles(files);
    } else {
      processingManager.clear();
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.processingProcessedTimer);
    this.stopProcessingTimer();

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  stopProcessingTimer = () => {
    clearInterval(this.processingTimerId);
    document.removeEventListener("visibilitychange", this.startProcessingTimer, false);
  }

  startProcessingTimer = () => {
    clearInterval(this.processingTimerId);

    if (document.visibilityState !== "visible") {
      return;
    }

    this.processingTimerId = setInterval(() => {
      this.processingTimer++;

      if ((this.processingTimer * 1000) > window.appConfig.processings.timeout) {
        this.stopProcessingTimer();
        this.handleProcessingTimeout();
      }
    }, 1000);
  }

  handleProcessingChanged = () => {
    if (window.clientConfig.isDebug) {
      console.log("Processing changed", processingManager.processing);
    }

    const processing = processingManager.processing;
    const startedCreatives = processing.getStartedCreatives();
    const processedCreatives = startedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = startedCreatives.filter((c) => c.isFailed);
    const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

    if (processedCreatives.isNotEmpty()) {
      clientStorage.incrementProcessedPhotosAmount();

      this.stopProcessingTimer();

      processingHelper.runOnceByExtra(processing, "processing_processed", () => {
        hitEvent(hits.PROCESSING_PROCESSED);
        signalEvent(signals.processingProcessed);
        logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
        logProcessingsTimings(elapsedMs);
      });

      const sessionIdx = parseInt(window.clientConfig.webviewParams["session_idx"] || 0);

      if (document.visibilityState === "visible"
        && sessionIdx !== clientStorage.getPreResultBannerSessionIdx()
        && window.clientConfig.features.showPreResultBanner
        && !this.processingRestored
      ) {
        clientStorage.setPreResultBannerSessionIdx(sessionIdx);

        webviewShowBanner("pre_result", () => {}, {
          result_url: encodeURIComponent(processedCreatives.first().result),
        });
      }

      if (window.clientConfig.isWebview && !window.clientConfig.isPro) {
        processingsCounter.addProcessing(processingManager.processing.id)
          .then((res) => {
            this.context.setCountProcessings(parseInt(res.data));
            this.props.history.replace(routes.RESULT);
          })
          .catch((e) => {
            console.error(e);
            this.props.history.replace(routes.RESULT);
          });
      } else {
        this.props.history.replace(routes.RESULT);
      }
    } else if (failedCreatives.length === startedCreatives.length) {
      this.stopProcessingTimer();

      const failedCreative = failedCreatives[0];

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
        signalEvent(signals.processingFailedPhotolab);
      } else {
        hitEvent(hits.PROCESSING_FAILED);
        signalEvent(signals.processingFailed);
      }

      logEvent(userEvents.PROCESSING_FAILED, {elapsed_time_ms: elapsedMs});

      processingManager.clear();

      this.setState({
        files: [],
        gender: null,
        faceCount: 0,
        imageUrl: null,
        imageIsBlurred: false,
        error: failedCreative.error,
      });
    }
  };

  startProcessing = async (files) => {
    const selectedFile = files.first();

    webviewAnalyticsEvent("photo_selected", [
      clientStorage.getSelectedPhotosAmount(),
      // selectedFile.rect.join(", "),
    ]);

    try {
      const skeletonTaskResult = await photolabSimpleTask(8800, selectedFile);
      const croppedImage = await promisifyImage(skeletonTaskResult.resultUrl);
      const file = {
        url: skeletonTaskResult.resultUrl,
      };

      const processing = new Processing();
      processing.setId(Date.now());
      processing.setLanguage(window.clientConfig.lang);
      processing.setFile(file, [0]);
      processing.setExtra(extraKeys.version, window.appConfig.processings.latestVersion);
      processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
      processing.setExtra(Processing.EXTRA_CROPPED_IMAGES_URLS, [file.url]);
      processing.setExtra(Processing.EXTRA_RANDOM_GENDER, [genderKeys.male, genderKeys.female].random());
      processing.setExtra(extraKeys.strength, "low");
      processing.setExtra(extraKeys.stylization, stylizationKeys.glamour);
      processing.setExtra(extraKeys.hairStylization, hairStylizationKeys.yes);
      // processing.setExtra("selected_file_rect", selectedFile.rect.join(", "));
      processing.setExtra("cropped_image_size", croppedImage.width + "x" + croppedImage.height);

      if (!skeletonTaskResult.humans) {
        throw new PhotolabResponseError(-9999, "This effect requires a straight-on half or full body image. Please try again with another photo.");
      }

      skeletonTaskResult.humans.forEach((human, index) => {
        const leftEyePoint = human.skeletonPoints.find((point) => point.groupName === "left_eye");
        const rightEyePoint = human.skeletonPoints.find((point) => point.groupName === "right_eye");
        processing.setExtra(
          "eyes_dist_" + (index + 1),
          Math.round(Math.hypot(leftEyePoint.x - rightEyePoint.x, leftEyePoint.y - rightEyePoint.y))
        );
      });

      const genders = [];
      if (skeletonTaskResult.humans.length === 1) {
        const genderData = await photolabGenderTask(file).then((taskResult) => taskResult.gender);
        const gender = genderData.value;
        genders.push(gender);

        const prompts = getPromptsForSingle();
        const promptsForMale = prompts.filter((p) => p.genders.indexOf(genderKeys.male) > -1);
        const promptsForFemale = prompts.filter((p) => p.genders.indexOf(genderKeys.female) > -1);

        processing.setExtra(extraKeys.hairStylization, hairStylizationKeys.no);

        processing.setExtra(Processing.EXTRA_ITEMS_ORDER_MALE, promptsForMale.map((p) => p.id));
        processing.setExtra(Processing.EXTRA_ITEMS_ORDER_FEMALE, promptsForFemale.map((p) => p.id));
        processing.setExtra(Processing.EXTRA_ITEMS, prompts);
        processing.setExtra(extraKeys.detectedGender, genderData);
        processing.setExtra(extraKeys.gender, gender);
      }
      else if (skeletonTaskResult.humans.length === 2) {
        const gender = genderKeys.male;
        genders.push(gender);

        const prompts = getPromptsForCouples().shuffle();
        processing.setExtra(Processing.EXTRA_ITEMS_ORDER, prompts.map((p) => p.id));
        processing.setExtra(Processing.EXTRA_ITEMS, prompts);
        processing.setExtra(Processing.EXTRA_FACE_COUNT, skeletonTaskResult.humans.length);
        processing.setExtra(extraKeys.gender, gender);
        processing.setExtra(extraKeys.hairStylization, hairStylizationKeys.no);
      }
      else {
        throw new PhotolabResponseError(-9999, "There is a maximum limit of 2 people in a photo for this effect to work.");
      }

      webviewAnalyticsEvent("photo_uploaded", [
        clientStorage.getSelectedPhotosAmount(),
        processing.getExtra("cropped_image_size"),
        skeletonTaskResult.humans.length,
        genders.first(),
        processing.getExtra(extraKeys.stylization),
        processing.getExtra(extraKeys.hairStylization),
      ], {
        "wt_barbify2": {
          "gender2": genders.last(),
          "eyes_dist": processing.getExtra("eyes_dist_1"),
        },
      });

      const creative = createCreativeByPromptConfig(
        processing,
        processing.getItemByPosition(0, processing.getExtra(extraKeys.gender)),
        processing.getExtra(extraKeys.gender),
        processing.getExtra(extraKeys.stylization),
        processing.getExtra(extraKeys.hairStylization),
        processing.getExtra(extraKeys.strength)
      );

      creative.setExtra(Creative.EXTRA_POSITION, 0);
      creative.removeExtra(Creative.EXTRA_KEEP_PENDING);
      creative.setAsSelected(true);

      processing.addCreative(creative);

      processingManager.start(processing);
      signalEvent(signals.processingStarted);

      webviewAnalyticsEvent("generation_start", [
        clientStorage.getSelectedPhotosAmount(),
        processing.getExtra("cropped_image_size"),
        skeletonTaskResult.humans.length,
        genders.first(),
        creative.getExtra(extraKeys.stylization),
        creative.getExtra(extraKeys.hairStylization),
      ], {
        "wt_barbify2": {
          "templ_id": creative.templateId,
          "pos": 1,
          "gender2": genders.last(),
          "eyes_dist": processing.getExtra("eyes_dist_1"),
        },
      });

      this.setState({
        imageUrl: file.url,
        imageIsBlurred: false,
        faceCount: processing.getExtra(Processing.EXTRA_FACE_COUNT, skeletonTaskResult.humans.length),
        gender: processing.getExtra(extraKeys.gender),
      });

      this.startProcessingTimer();
    } catch (err) {
      console.error(err);

      webviewAnalyticsEvent("photo_upload_error", [
        clientStorage.getSelectedPhotosAmount(),
        err.code,
        err.message,
      ]);

      this.setState({
        files: [],
        gender: null,
        imageUrl: null,
        imageIsBlurred: false,
        faceCount: 0,
        error: normalizeError(err),
      });
    }
  }

  handleProcessingTimeout = () => {
    processingManager.clear();

    this.setState({
      files: [],
      gender: null,
      imageUrl: null,
      imageIsBlurred: false,
      faceCount: 0,
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
    });
  };

  handleFilesSelected = (files) => {
    hitEvent(hits.INDEX_PHOTO_SELECT);
    signalEvent(signals.photoSelected);
    logEvent(userEvents.PHOTO_SELECT, {place: "processing"});

    this.setState({
      files: [],
      gender: null,
      imageUrl: null,
      imageIsBlurred: false,
      faceCount: 0,
      error: null,
    }, () => {
      processingManager.clear();
      this.handleUploadFiles(files);
    });
  }

  handleUploadFiles = (files) => {
    if (!(files instanceof FileList)) {
      this.handleFilesUploaded(files);
      return;
    }

    Promise.all([...files].map((image) => uploadHandler(image)))
      .then(this.handleFilesUploaded)
      .catch((error) => {
        this.setState({error});
      });
  }

  handleFilesUploaded = (files) => {
    this.setState({files});
    this.startProcessing(files);
  }

  handleCancel = () => {
    webviewAnalyticsEvent("cancel_generation_tapped", [
      clientStorage.getSelectedPhotosAmount(),
    ]);

    processingManager.clear();
    this.props.history.replace(routes.INDEX);
  }

  handleGenderSelect = (nextGender) => {
    this.setState({gender: nextGender});

    const processing = processingManager.processing;
    if (!processing) {
      return;
    }

    const currentCreative = processing.creatives.pop();
    const prompt = processing.getItemByPosition(0, nextGender);
    const nextCreative = createCreativeByPromptConfig(
      processing,
      prompt,
      nextGender,
      currentCreative.getExtra(extraKeys.stylization),
      currentCreative.getExtra(extraKeys.hairStylization),
      currentCreative.getExtra(extraKeys.strength)
    );

    nextCreative.setExtra(Creative.EXTRA_POSITION, 0);
    nextCreative.setAsSelected(true);

    processing.addCreative(nextCreative);
    processing.setExtra(extraKeys.gender, nextGender);
    processingManager.update();
  }

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFilesSelected={this.handleFilesSelected} />;
    }

    return <LoadingView
      images={this.state.files}
      gender={this.state.gender}
      faceCount={this.state.faceCount}
      onGenderSelect={this.handleGenderSelect}
      onCancel={this.handleCancel}
    />;
  }
}

ProcessingPage.contextType = AppContext;
