import React from "react";
import { BannerViewContainer } from "./BannerView.style";

export default class BannerView extends React.Component {
  render() {
    return (
      <BannerViewContainer full={this.props.full}  black={this.props.black}>
        {this.props.children}
      </BannerViewContainer>
    )
  }
}
